import { ref, computed, reactive, watch, nextTick } from 'vue';
import { useUrlSearchParams } from '@vueuse/core';
import { router, usePage } from '@inertiajs/vue3';
import debounce from 'lodash.debounce';
import { useToast } from 'vue-toastification';
import { formatMessage } from './shared';

export function useFilterFetch() {
    // get current state from url
    // update url when mutating state
    // do not return items. It will be returned by page redirection

    const urlSearchParams = useUrlSearchParams('history');

    const order = computed({
        get() {
            return {
                by: urlSearchParams.orderBy,
                direction: urlSearchParams.orderDirection,
            };
        },
        set({ by, direction }) {
            router.get(location.pathname, { ...urlSearchParams, orderBy: by, orderDirection: direction });
        },
    });

    const q = computed({
        get() {
            return urlSearchParams.q;
        },
        set: debounce(val => {
            router.get(
                location.pathname,
                { ...urlSearchParams, page: 1, q: val || undefined },
                { preserveState: true },
            );
        }, 500),
    });

    const page = computed({
        get() {
            return urlSearchParams.page ? Number(urlSearchParams.page) : 1;
        },
        set(val) {
            router.get(location.pathname, { ...urlSearchParams, page: val });
        },
    });

    return {
        order,
        q,
        page,
    };
}

export function useModal(initOpen = false) {
    const isOpen = ref(initOpen);

    return reactive({
        isOpen,
        open: () => (isOpen.value = true),
        close: () => (isOpen.value = false),
    });
}

export function useFlashMessages() {
    const page = usePage();
    const toast = useToast();

    watch(() => page.props.flash?.notification, notification => {
        if (!notification) return;
        const { type = 'success', content } = notification;

        if (type && content) {
            toast[type](content);
        }
    }, { immediate: true });
}

export class RoleplayQuotaReached extends Error {
    constructor({ userType, location, message }) {
        super(message);
        this.name = 'RoleplayQuotaReached';
        this.userType = userType;
        this.location = location;
    }
}

/**
 * @typedef {Object} Message
 * @property {string} id - Unique identifier for the message
 * @property {string} thread_id - Identifier for the thread this message belongs to
 * @property {('assistant'|'user')} role - Role of the message sender
 * @property {string} content - The content of the message
 * @property {number|null} rating - Rating of the message, if any
 * @property {string} created_at - ISO 8601 timestamp of when the message was created
 * @property {string} updated_at - ISO 8601 timestamp of when the message was last updated
 * @property {Object} author - Information about the message author
 * @property {string} author.name - Name of the author
 * @property {string|null|undefined} author.avatar - URL of the author's avatar image (optional)
 */

export function useChat(initalState = 'ready', simRun = ref(null)) { 
    const toast = useToast();
    const xpModal = useModal(false);

    const isWaitingReply = ref(false);

    const simulationRun = ref();

    watch(simRun, val => {
        simulationRun.value = val
    }, { immediate: true, deep: true });

    const isReviewed = computed(() => {
        return !!simulationRun.value?.result;
    });

    const isReviewing = ref(false);

    const review = computed(() => simulationRun.value?.review);

    const isXpsCalculated = computed(() => {
        return !!simulationRun.value?.xps;
    }); 

    const isCalculatingXps = ref(false);

    const xps = computed(() => simulationRun.value?.xps);
    const totalXps = computed(() => {
        if (!xps.value) return 0;
        return Object.values(xps.value).reduce((total, xp) => total += xp, 0);
    })

    /**
     * @type {import('vue').Ref<Message[]>}
     */
    const messages = ref([]);
    watch(() => simulationRun.value?.messages, val => messages.value = val ?? [], { immediate: true, deep: true });

    const chatInput = ref('');
      
    const api = {
        async createSimulationRun(simulationId, introMessage, lang) {
            const response = await fetch(route('simulationRuns.store', { lang }), {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    'X-CSRF-Token': usePage().props.csrf_token,
                },
                credentials: 'same-origin',
                body: JSON.stringify({ selectedSimulation: simulationId, introMessage }),
            });

            if (response.status === 403) {
                const data = await response.json();
                const { message, quotaReached, userType, location } = data;
                throw new RoleplayQuotaReached({ userType, location, message });
            }
        
            if (!response.ok) {
                throw new Error(response.statusText);
            }
        
            const simulationRun = await response.json();
        
            return simulationRun;
        },

        /**
         * 
         * @param {string} message
         * @returns {Promise<{ message: Message, reply?: Message | null, audioReply?: string, discussionEnded?: boolean }>}
         */
        async sendMessage(message, simulationRunId, { returnAudio = false } = {}) {
            const payload = { prompt: message, returnAudio };
            
            const response = await fetch(route('simulationRuns.prompt', { simulationRun: simulationRunId }), {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    'X-CSRF-Token': usePage().props.csrf_token,
                },
                credentials: 'same-origin',
                body: JSON.stringify(payload),
            });

            if (!response.ok) {
                throw new Error(response.statusText);
            }

            const { message: rawMessage, reply, audioReply, discussionEnded } = await response.json();

            return { message: formatMessage(rawMessage), reply: reply ? formatMessage(reply) : null, audioReply, discussionEnded };
        },

        async submitFeedbackReview(simulationRunId) {
            const response = await fetch(route('simulationRuns.review', { simulationRun: simulationRunId }), {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    'X-CSRF-Token': usePage().props.csrf_token,
                },
                credentials: 'same-origin',
            });
        
            if (!response.ok) {
                throw new Error(response.statusText);
            }
        
            const reviewMessage = await response.json();
    
            return formatMessage(reviewMessage);     
        },
        
        async submitXpReview(simulationRunId) {
            const response = await fetch(route('simulationRuns.reviewExperience', { simulationRun: simulationRunId }), {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    'X-CSRF-Token': usePage().props.csrf_token,
                },
                credentials: 'same-origin',
            });
        
            if (!response.ok) {
                throw new Error(response.statusText);
            }
        
            const { xps, score } = await response.json();
    
            return { xps, score };     
        },

        async regenerateMessage(messageId) {
            const response = await fetch(route('messages.update', { message: messageId }), {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    'X-CSRF-Token': usePage().props.csrf_token,
                },
                credentials: 'same-origin',
                body: JSON.stringify({ _method: 'PATCH' }),
            });

            if (!response.ok) {
                throw new Error(response.statusText);
            }

            const message = await response.json();
            return formatMessage(message);
        }
    }

    const audio = {
        playOnce(audioReply) {
            const audio = new Audio(audioReply);
            audio.play();
            router.post(route('simulationRuns.destroy.audio'), {  
                audio: audioReply
            });
        }
    }


    /**
     * Optimistically update UI
     * @param {{ content: string, author: { name: string avatar?: string } }} message 
     * @returns {{ rollback: Function, tmpMessage: Object }} Rollback function to remove optimistically added message
     */
    function addMessage(message) {
        const tmpMessage = {
            id: Date.now(), // temporary ID
            author: message.author,
            avatar: undefined,
            content: message.content,
            createdAt: new Date(),
        }

        const index = messages.value.push(tmpMessage) - 1;

        return {
            // remove the message from the UI
            rollback() {
                messages.value.splice(index, 1);
            },
            tmpMessage,
        }
    }

    /**
     * @param {{ content: string, author: { name: string avatar?: string } }} message 
     */
    async function handleSendMessage(message, simulationRunId) {
        const { rollback: revertMessage, tmpMessage } = addMessage(message);

        isWaitingReply.value = true;
        chatInput.value = '';
    
        // TODO: usedListening
        try {
            const { message: serverMessage, reply, audioReply, discussionEnded } = await api.sendMessage(message.content, simulationRunId);
    
            // replace the tmpMessage with the one we got from server with real ID
            messages.value.splice(
                messages.value.indexOf(tmpMessage),
                1,
                serverMessage
            );
    
            if (reply) {
                messages.value.push(reply);
            }

            if (audioReply) {
                audio.playOnce(audioReply);
            }

            if (discussionEnded) {
                handleReview();
                toast.info(`${simulationRun.value.simulation.impersonator.name} has left the discussion.`);
            }
        } catch (e) {
            toast.error("Something went wrong :( Try again in few moments.")
            revertMessage();
    
            // repopulate the prompt input with original message
            prompt.value = message.content;

            throw e;
        } finally {
            isWaitingReply.value = false;
            nextTick(() =>document.getElementById('chat-input').focus());
        }

    }

    /**
     * @param {{ content: string, author: { name: string avatar?: string } }} message 
     */
    async function handleStartSimulationRun(message, simulationId, introMessage, lang) {
        isWaitingReply.value = true;
        chatInput.value = '';

        const { rollback: revertMessage, tmpMessage } = addMessage(message);
        try {
            const simulationRun = await api.createSimulationRun(simulationId, introMessage, lang);
            const { message: serverMessage, reply, audioReply } = await api.sendMessage(message.content, simulationRun.id);

            // replace the tmp message with real one from server
            messages.value.splice(
                messages.value.indexOf(tmpMessage),
                1,
                serverMessage
            );

            messages.value.push(reply)

            if (audioReply) {
                audio.playOnce(audioReply);
            }

            return simulationRun;
        } catch (e) {
            revertMessage();
            chatInput.value = message.content;
            throw e;
        } finally {
            isWaitingReply.value = false;
        }
    }

    async function handleRegenerateMessage(messageId) {
        const messageIndex = messages.value.findIndex(m => m.id === messageId);
        if (messageIndex === -1) {
            toast.error("Something went wrong :( Try again in few moments.");
            return;
        }

        const message = messages.value[messageIndex];

        // remove the message
        messages.value.splice(messageIndex, 1);

        try {
            const isReviewMessage = message.id === simulationRun.value.result?.id;

            let newMessage = null;

            if (isReviewMessage) {
                isReviewing.value = true;
                newMessage = await api.submitFeedbackReview(simulationRun.value.id);
                simulationRun.value.result = newMessage;
            } else {
                isWaitingReply.value = true;
                newMessage = await api.regenerateMessage(messageId);
            }

            // put new message in place
            messages.value.splice(messageIndex, 0, newMessage);
        } catch {
            // put the original message back
            messages.value.splice(messageIndex, 0, message);
        } finally {
            isWaitingReply.value = false;
            isReviewing.value = false;
        }
    }

    async function handleFeedbackReview() {
        isReviewing.value = true;
        try {
            const message = await api.submitFeedbackReview(simulationRun.value.id);
            simulationRun.value.result = message;
            messages.value.push(message);
        } finally {
            isReviewing.value = false;
        }
    }

    async function handleXpReview() {
        isCalculatingXps.value = true;
        try {
            const { xps, score } = await api.submitXpReview(simulationRun.value.id);
            simulationRun.value.xps = xps;
            simulationRun.value.score = score;
        } finally {
            isCalculatingXps.value = false;
        }
    }

    async function handleReview() {
        isReviewing.value = true;
        isCalculatingXps.value = true;

        const xpReviewPromise = api.submitXpReview(simulationRun.value.id)
            .then(xpReview => {
                simulationRun.value.xps = xpReview.xps;
                simulationRun.value.score = xpReview.score;
                xpModal.open();
            })
            .finally(() => {
                isCalculatingXps.value = false;
            });

        const reviewPromise = api.submitFeedbackReview(simulationRun.value.id).then(async message => {
            xpReviewPromise.finally(() => {
                simulationRun.value.result = message;
                messages.value.push(message);
            });
        })
        .finally(() => {
            isReviewing.value = false;
        });

        const promises = await Promise.allSettled([xpReviewPromise, reviewPromise]);
        
        if (promises.every(p => p.status === 'rejected')) {
            toast.error("Something went wrong :( Try again in few moments.")
        }
    }

    return {
        api,
        messages,
        chatInput,
        review,
        xps,
        xpModal,
        totalXps,
        isReviewing,
        isReviewed,
        isCalculatingXps,
        isXpsCalculated,
        isWaitingReply,
        addMessage,
        handleSendMessage,
        handleStartSimulationRun,
        handleRegenerateMessage,
        handleXpReview,
        handleFeedbackReview,
        handleReview,
    }
}
