export function deepen(obj) {
    const result = {};
    for (const objectPath in obj) {
        const parts = objectPath.split('.');
        let target = result;
        while (parts.length > 1) {
            const part = parts.shift();
            target = target[part] = target[part] || {};
        }
        target[parts[0]] = obj[objectPath];
    }
    return result;
}

/**
 * Convert every property of given object into camelCase
 * @param {Object} obj
 * @returns {Object}
 */
export function camelCaseProps(obj) {
    const camelCaseObj = {};

    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            const camelCaseKey = key.replace(/_([a-z])/g, (g) => g[1].toUpperCase());
            camelCaseObj[camelCaseKey] = obj[key];
        }
    }

    return camelCaseObj;
}

/**
 * Create new object having only specified properties
 * @param {Object} obj 
 * @param {Array<string>} props list of properties to pick from object
 * @returns {Object} object having only the specified properties
 */
export function pickProps(obj, props) {
    const newObj = {};
    props.forEach(prop => {
        if (obj.hasOwnProperty(prop)) {
            newObj[prop] = obj[prop];
        }
    });

    return newObj;
}

export function capitalize(name) {
    return name
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .join(' ');
};

export function pluralize(word, count) {
    return new Intl.PluralRules('en-US', { type: 'cardinal' }).select(count) === 'one' ? word : word + 's';
}

export function copyToClipboard(text) {
    navigator.clipboard.writeText(text);
}

/**
 * 
 * @param {RawMessage} message Message as it comes from backend 
 * @return {Message}
 */
export function formatMessage(message) {
    const m = camelCaseProps(message);
    return {
        id: m.id,
        content: m.content,
        rating: m.rating?.value,
        author: m.author,
        createdAt: new Date(m.createdAt),
        threadId: m.threadId,
        thread: m.thread
    }
}

export async function trimAudioBlob(audioChunks, startTime) {
    // Combine all audio chunks into a single Blob
    const fullAudio = new Blob(audioChunks, { type: 'audio/webm' });

    // Create an AudioContext
    const audioContext = new (window.AudioContext || window.webkitAudioContext)();

    // Convert the Blob to an ArrayBuffer
    const arrayBuffer = await fullAudio.arrayBuffer();

    // Decode the audio data
    const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);

    // Calculate the start position in samples
    const sampleRate = audioBuffer.sampleRate;
    const startSample = Math.floor(startTime * sampleRate);

    // Create a new AudioBuffer for the trimmed audio
    const trimmedBuffer = audioContext.createBuffer(
        audioBuffer.numberOfChannels,
        audioBuffer.length - startSample,
        sampleRate
    );

    // Copy the trimmed portion of the audio data
    for (let channel = 0; channel < audioBuffer.numberOfChannels; channel++) {
        const channelData = audioBuffer.getChannelData(channel);
        trimmedBuffer.copyToChannel(channelData.subarray(startSample), channel);
    }

    // Convert the trimmed AudioBuffer to a WAV Blob
    const wavBlob = audioBufferToWav(trimmedBuffer);

    return wavBlob;
}

// Helper function to convert AudioBuffer to WAV
function audioBufferToWav(buffer) {
    const numOfChan = buffer.numberOfChannels;
    const length = buffer.length * numOfChan * 2;
    const audioData = new ArrayBuffer(44 + length);
    const view = new DataView(audioData);
    const channels = [];
    let sample, offset = 0;

    // Write WAV header
    writeString(view, 0, 'RIFF');
    view.setUint32(4, 36 + length, true);
    writeString(view, 8, 'WAVE');
    writeString(view, 12, 'fmt ');
    view.setUint32(16, 16, true);
    view.setUint16(20, 1, true);
    view.setUint16(22, numOfChan, true);
    view.setUint32(24, buffer.sampleRate, true);
    view.setUint32(28, buffer.sampleRate * 2 * numOfChan, true);
    view.setUint16(32, numOfChan * 2, true);
    view.setUint16(34, 16, true);
    writeString(view, 36, 'data');
    view.setUint32(40, length, true);

    // Write interleaved audio data
    for (let i = 0; i < buffer.numberOfChannels; i++)
        channels.push(buffer.getChannelData(i));

    offset = 44;
    for (let i = 0; i < buffer.length; i++) {
        for (let channel = 0; channel < numOfChan; channel++) {
            sample = Math.max(-1, Math.min(1, channels[channel][i]));
            sample = (0.5 + sample < 0 ? sample * 32768 : sample * 32767) | 0;
            view.setInt16(offset, sample, true);
            offset += 2;
        }
    }

    return new Blob([audioData], { type: 'audio/wav' });
}

// Helper function to write a string to a DataView
function writeString(view, offset, string) {
    for (let i = 0; i < string.length; i++) {
        view.setUint8(offset + i, string.charCodeAt(i));
    }
}

export const supportedLanguages = { 
    'english': { lang: 'en', rfc5646: 'en-US'},
    'slovak': { lang: 'sk', rfc5646: 'sk-SK'}, 
    'czech': { lang: 'cs', rfc5646: 'cs-CZ'},
    'afrikaans': { lang: 'af', rfc5646: 'af-ZA'}, 
    'arabic': { lang: 'ar', rfc5646: 'ar-AE'}, 
    'croatian': { lang: 'hr', rfc5646: 'hr-HR'}, 
    'danish': { lang: 'da', rfc5646: 'da-DK'},
    'dutch': { lang: 'nl', rfc5646: 'nl-NL'}, 
    'estonian': { lang: 'et', rfc5646: 'et-EE'},
    'finnish': { lang: 'fi', rfc5646: 'fi-FI'}, 
    'french': { lang: 'fr', rfc5646: 'fr-FR'},
    'german': { lang: 'de', rfc5646: 'de-DE'}, 
    'greek': { lang: 'el', rfc5646: 'el-GR'}, 
    'hebrew': { lang: 'he', rfc5646: 'he-IL'},
    'hungarian': { lang: 'hu', rfc5646: 'hu-HU'}, 
    'italian': { lang: 'it', rfc5646: 'it-IT'}, 
    'japanese': { lang: 'ja', rfc5646: 'ja-JP'},
    'latvian': { lang: 'lv', rfc5646: 'lv-LV'}, 
    'lithuanian': { lang: 'lt', rfc5646: 'lt-LT'}, 
    'macedonian': { lang: 'mk', rfc5646: 'mk-MK'},
    'polish': { lang: 'pl', rfc5646: 'pl-PL'}, 
    'portuguese': { lang: 'pt', rfc5646: 'pt-PT'}, 
    'romanian': { lang: 'ro', rfc5646: 'ro-RO'},
    'russian': { lang: 'ru', rfc5646: 'ru-RU'}, 
    'serbian': { lang: 'sr', rfc5646: 'sr-SP'},
    'slovenian': { lang: 'sl', rfc5646: 'sl-SI'}, 
    'spanish': { lang: 'es', rfc5646: 'es-ES'},
    'swedish': { lang: 'sv', rfc5646: 'sv-SE'},
    'turkish': { lang: 'tr', rfc5646: 'tr-TR'}, 
    'ukrainian': { lang: 'uk', rfc5646: 'uk-UA'},
    };

export function can(user, action, model) {
    switch(action) {
        case 'duplicate':
            if (user.isDemoUser) return false;
            if (!user.hasBuilderAccess) return false;
            return model.isDuplicatable || user.id === model.author_id;
        default:
            throw new Error(`Not implemented permission guard for action: ${action}`);
    }
}
